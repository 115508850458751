import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setBack2Page, setPageName } from "../../../../store/pagination/paginationSlice";
import { DeleteTwoTone, EditTwoTone, EnvironmentTwoTone, InboxOutlined, PlusSquareTwoTone, QuestionCircleFilled } from "@ant-design/icons";
import { Button, DatePicker, Input, message, Modal, notification, Popconfirm, Select, Space, Table } from "antd";
import styles from './ScholarshipUploadList.module.css';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import type { UploadFile } from 'antd/es/upload/interface';
import axios from "axios";
import ApiConfig from "../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { Scholarship90Data } from "../../../../models/Scholarship90Model";
import { convertDBDate2Show } from "../../../../helper/DateTimeHelper";
import { SessionUserState } from "../../../../store/authen/authenSlice";
import { ApiResponse, Faculty, FacultyBranch } from "../../../../models/MasterModel";
import { addScholarship90, deleteScholarship90, getScholarship90ByParam } from "../../../../services/ScholarShip90Service";
import { RcFile } from "antd/lib/upload";
import { PermissionName } from "../../../../models/Login";
import { getAuthPermission } from "../../../../services/AuthenService";
import { getDepartment, getFaculty, getFacultyBranch, getMajor } from "../../../../services/MasterService";
import ScholarShipUploadListSearchSection from "./SearchSection/ScholarShipUploadListSearchSection";
import { IBackendPageProps } from "../../models";
import PermissionHelper from "../../../../helper/PermissionHelper";
import { getStatusTextColorCode, labelLang } from "../../../../helper/HTMLHelper";
import { LanguageState } from "../../../../store/language/languageSlice";


const ScholarshipList = () => {
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const dispatch = useAppDispatch();
    const { RangePicker } = DatePicker;
    let navigate = useNavigate();

    const [dataList, setDataList] = useState<Scholarship90Data[]>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [refName, setRefName] = useState<string>("");
    const [userId, setUserId] = useState<number>(1);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [currentUploadFile, setCurrentUploadFile] = useState<UploadFile[] | undefined>();
    const [hasSearchPermission, setHasSearchPermission] = useState<boolean>(false);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");
    const [uploadName, setUploadName] = useState<string>("");
    const [schoolYear, setSchoolYear] = useState<string>("");
    const [selectedFaculty, setSelectedFaculty] = useState<string>("ALL");
    const [selectedFacultyBranch, setSelectedFacultyBranch] = useState<string>("ALL");
    const [selectedStatus, setSelectedStatus] = useState<string>("ALL");

    const [currentPermission, setCurrentPermission] = useState<any>(null);

    const permissionHelper = new PermissionHelper();
    const relavantPermissionList = [PermissionName.SCHOLAR90_SEARCH];
    
    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])
    
    const columns = [
        {
            title: labelLang('ผู้อัปโหลด', "Sentder Name", currentLang),
            key: 'upload_user_name',
            sorter: (a: Scholarship90Data, b: Scholarship90Data) => `${a.upload_user_firstname} ${a.upload_user_lastname}`.localeCompare(`${b.upload_user_firstname} ${b.upload_user_lastname}`),
            render: (data: Scholarship90Data) => {return `${data.upload_user_firstname} ${data.upload_user_lastname}`},
        },
        {
            title: labelLang('คณะ', "Faculty", currentLang),
            dataIndex: 'faculty_name',
            key: 'faculty_name',
            sorter: (a: Scholarship90Data, b: Scholarship90Data) => a.faculty_name != undefined && b.faculty_name != undefined ? a.faculty_name.localeCompare(b.faculty_name) : 0,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang('สาขา', "Major", currentLang),
            dataIndex: 'major_name',
            key: 'major_name',
            sorter: (a: Scholarship90Data, b: Scholarship90Data) => a.major_name != undefined && b.major_name != undefined ? a.major_name.localeCompare(b.major_name) : 0,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang('วันที่สร้าง', "Sent Date", currentLang),
            key: 'create_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            sorter: (a: Scholarship90Data, b: Scholarship90Data) => a.create_date.localeCompare(b.create_date),
            render: (data: Scholarship90Data) => {return convertDBDate2Show(data.create_date)},
        },
        {
            title: labelLang('สถานะปัจจุบัน', "Status", currentLang),
            dataIndex: 'status',
            key: 'status',
            // sorter: (a: ProcessTransaction, b: ProcessTransaction) => a.age - b.age,
            // render: (data: any) => {data.age},
            render: (data: string, item: Scholarship90Data, index: number) => {return <span style={{color: getStatusTextColorCode(data)}}>{data}</span>}
        },
        {
          title: 'Action',
          key: 'action',
          render: (text: string, record: Scholarship90Data) => (
            <Space size="middle">
                <Link to={`/management/scholarship/form/${record.id}`}><EditTwoTone /></Link>
                { record.create_user_id === userId && record.status === 'DRAFT' ?
                <Popconfirm 
                    title="ต้องการลบข้อมูลนี้หรือไม่"
                    onCancel={() => {}}
                    onConfirm={() => {removeData(record.id)}}
                    icon={<QuestionCircleFilled />}
                >
                    <DeleteTwoTone />
                </Popconfirm> : null
                }
                {/* <a onClick={() => {removeData(record.id)}}><DeleteTwoTone /></a> */}
            </Space>
          ),
        },
    ]
    useEffect(() => {
        dispatch(setPageName({th: "รายการทุน 90 ปี", en: "Scholarship List"}));
        dispatch(setBack2Page(null));
        loadUserPermission();
    }, [])

    useEffect(() => {
        if(authen && authen.sessionUser){
            search();
            loadUserPermission();
            setUserId(authen.sessionUser.currentUser.id);
        }
    }, [authen])

    useEffect(() => {
        if(currentUploadFile){
            console.log("currentUploadFile: ", currentUploadFile, uploadFiles);
            setFileList([...fileList, ...currentUploadFile]);
            uploadFiles = [];
            setCurrentUploadFile(undefined);
        }
    }, [currentUploadFile])

    const loadUserPermission = async () => {
        if(authen && authen.sessionUser){
            setUserId(authen.sessionUser.currentUser.id);
            await permissionHelper.loadUserPermission(relavantPermissionList, authen);
            setHasSearchPermission(permissionHelper.hasPermission(PermissionName.SCHOLAR90_SEARCH));
        }
    }

    // const loadUserPermission = async () => {
    //     console.log("loadUserPermission");
    //     if(authen && authen.sessionUser){
    //         console.log("loadUserPermission auth");
    //         let permNameList = [PermissionName.THESIS_SEARCH];
    //         let userId = authen.sessionUser.currentUser.id;
    //         let resData: ApiResponse = await getAuthPermission(userId, permNameList);
    //         if(resData.status){
    //             let data: {[key: string]: string} = resData.data;
    //             console.log("Permission Data: ", data);
    //             setCurrentPermission(data);
    //             let hasSearchPerm = data[PermissionName.THESIS_SEARCH] != undefined && data[PermissionName.THESIS_SEARCH] != null;
    //             setHasSearchPermission(hasSearchPerm);
    //             // setCanApprove(hasApprovePerm);
    //             // setHasChangeStatusPerm(hasApprovePerm || hasRejectPerm);
    //         }
    //     }

    // }
    
    const removeData = async (id: any) => {
        console.log("removeData: ", id);
        if(authen && authen.sessionUser){
            let resData: ApiResponse = await deleteScholarship90(id, authen.sessionUser.currentUser.id);
            if(resData.status){
                // onSearch();
                // navigate(`/management/scholarship/form/${scholar.id}`);
                notification.success({message: "ลบข้อมูลสำเร็จ", description: resData.message})
                search();
            }
        }
    }
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        // console.log('params', pagination, filters, sorter, extra);
    }
    const handleCancel = () => {setPreviewVisible(false)};
    const refNameChange = (e: any) => {
        setRefName(e.target.value);
    }
    const openAddModal = () => {
        setPreviewVisible(true);
    }
    const addNewScholar = async () => {
        if(authen && authen.sessionUser){
            let formdata = new FormData();
            let files: RcFile[] = fileList.map((value) => { return value as RcFile});
            formdata.append("create_user_id", "" + authen.sessionUser.currentUser.id);
            for(let i = 0; i < files.length; i++){
                formdata.append(`file[${i}]`, files[i]);
            }
            
            let resData: ApiResponse = await addScholarship90(formdata);
            if(resData.status){
                let scholar: Scholarship90Data = resData.data.scholar;
                notification.success({message: labelLang("บันทึกข้อมูลสำเร็จ", "Create Success", currentLang), description: resData.message})
                navigate(`/management/scholarship/form/${scholar.id}`);
            }else{
                notification.success({message: labelLang("เกิดข้อผิดพลาด", "Create Fail", currentLang), description: resData.message})
            }
        }
        // navigate('/management/scholarship/form/1')
    }
    const search = () => {
        if(authen && authen.sessionUser){
            let criteria = {
                    user_id: authen.sessionUser.currentUser.id,
                    upload_name: uploadName.trim() == "" ? null : uploadName.trim(),
                    school_year: schoolYear.trim() == "" ? null : schoolYear.trim(),
                    faculty_id: selectedFaculty == "ALL" ? null : selectedFaculty,
                    branch_id: selectedFacultyBranch == "ALL" ? null : selectedFacultyBranch,
                    major_id: selectedMajor == "ALL" ? null : selectedMajor,
                    status: selectedStatus == "ALL" ? null : selectedStatus
            }
            onSearch(criteria);
        }
    }
    const onSearch = async (searchParams: any) => {
        if(authen && authen.sessionUser){
            let resData = await getScholarship90ByParam(searchParams);
            if(resData.status){
                let scholarList: Scholarship90Data[] = resData.data;
                setDataList(scholarList);
            }
        }
    }

    let uploadFiles: UploadFile[] = [];
    const props: UploadProps = {
        multiple: true,
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
        onRemove: file => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: file => {
        //   setFileList([...fileList, file]);
            console.log("beforeUpload: ", file);
            uploadFiles.push(file)
            setCurrentUploadFile(uploadFiles);
            return false;
        },
        customRequest: (options) => {
            const { onSuccess, onError, file, onProgress } = options;
            // setFileList([...fileList, file]);
        },
        fileList,
    };
    const renderSearchContainer = () => {
        let searchContainer = (
            <ScholarShipUploadListSearchSection
                onSearch={onSearch}
                userId={userId}
            />
        );
        if(true){
            return hasSearchPermission ? searchContainer : null;
        }
    }
    return (
        <div className={styles['main-container']}>
            {renderSearchContainer()}
            <div className={styles['list-container']}>
                <div className={styles['button-container']}>
                    <Button type={'primary'} onClick={() => {openAddModal()}}>เพิ่มรายการใหม่</Button>
                </div>
                <Table columns={columns} dataSource={dataList} onChange={onChange}/>
            </div>
            <Modal
                visible={previewVisible}
                title={labelLang("เพิ่มรายการส่งไฟล์ใหม่", "", currentLang)}
                onCancel={() => {handleCancel()}}
                onOk={() => {addNewScholar()}}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']}>
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                    </Dragger>
                        {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                    </div>
                    {/* <div className={styles['input-container']}>
                        <Button style={{marginRight: 5}} type={"primary"} icon={<SaveOutlined />} onClick={addNewTransaction}>บันทึก</Button>
                        <Button style={{marginLeft: 5}} type={"primary"} danger icon={<CloseOutlined />} onClick={handleCancel}>ยกเลิก</Button>
                    </div> */}
                </div>
            </Modal>
        </div>
    )
}

export default ScholarshipList;