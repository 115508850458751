import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setBack2Page, setPageName } from "../../../../store/pagination/paginationSlice";
import { CaretRightOutlined, DeleteTwoTone, EditTwoTone, EnvironmentTwoTone, InboxOutlined, PlusSquareTwoTone, QuestionCircleFilled } from "@ant-design/icons";
import { Button, Collapse, DatePicker, Input, message, Modal, notification, Popconfirm, Select, Space, Table } from "antd";
import styles from './ThesisUploadList.module.css';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import type { UploadFile } from 'antd/es/upload/interface';
import axios from "axios";
import ApiConfig from "../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { authen, getAuthPermission } from "../../../../services/AuthenService";
import { SessionUserState } from "../../../../store/authen/authenSlice";
import { addThesis, checkDuplicateTitle, deleteThesis, getThesisByParam } from "../../../../services/ThesisService";
import { ThesisData } from "../../../../models/ThesisModel";
import { ApiResponse, Faculty, FacultyBranch } from "../../../../models/MasterModel";
import { convertDBDate2Show } from "../../../../helper/DateTimeHelper";
import { getDepartment, getFaculty, getFacultyBranch, getMajor } from "../../../../services/MasterService";
import { PermissionName } from "../../../../models/Login";
import PermissionHelper from "../../../../helper/PermissionHelper";
import { getStatusTextColorCode, labelLang } from "../../../../helper/HTMLHelper";
import { getActivateSettingById } from "../../../../services/ActivateSettingService";
import { IActivateSetting } from "../../../../models/ActivateSetting";
import { LanguageState } from "../../../../store/language/languageSlice";

const ThesisUploadList = () => {
    const dispatch = useAppDispatch();
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const { RangePicker } = DatePicker;
    const {Option} = Select;
    const dateFormat = 'DD/MM/YYYY';
    let navigate = useNavigate();

    const [searchFromDate, setSearchFromDate] = useState();
    const [searchRefName, setSearchRefName] = useState();
    const [dataList, setDataList] = useState<any>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [photoDate, setPhotoDate] = useState<any>(moment());
    const [refName, setRefName] = useState<string>("");
    const [userId, setUserId] = useState<number>(1);
    const [facultyComboList, setFacultyComboList] = useState<Faculty[]>([]);
    const [oriFacultyBranchComboList, setOriFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [filterFacultyBranchComboList, setFilterFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [uploadName, setUploadName] = useState<string>("");
    const [schoolYear, setSchoolYear] = useState<string>("");
    const [selectedFaculty, setSelectedFaculty] = useState<string>("ALL");
    const [selectedFacultyBranch, setSelectedFacultyBranch] = useState<string>("ALL");
    const [selectedStatus, setSelectedStatus] = useState<string>("ALL");
    const [oriMajorComboList, setOriMajorComboList] = useState<FacultyBranch[]>([]);
    const [filterMajorComboList, setFilterMajorComboList] = useState<FacultyBranch[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");
    const [activeKey, setActiveKey] = useState<string | string[]>("0");

    const [hasSearchPermission, setHasSearchPermission] = useState<boolean>(false);

    const [thesisName, setThesisName] = useState<string>('');

    const permissionHelper = new PermissionHelper();
    const relavantPermissionList = [PermissionName.THESIS_SEARCH];

    const [settingData, setSettingData] = useState<IActivateSetting | null>(null);
    useEffect(() => {
        dispatch(setPageName({th: "รายการเอกสารวิทยานิพนธ์", en: "Thesis submission summary"}));
        dispatch(setBack2Page(null));
        loadComboData();
        onSearch();
        loadUserPermission();
        loadSetting();
    }, [])

    useEffect(() => {
        if(authen && authen.sessionUser){
            onSearch();
            loadUserPermission();
        }
    }, [authen])

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])

    const loadComboData = async () => {
        let facultyList = await getFaculty();
        // let facultyBranchList = await getFacultyBranch();
        let departmentList = await getDepartment();
        let majorList = await getMajor();
        setFacultyComboList(facultyList);
        setOriFacultyBranchComboList(departmentList);
        setFilterFacultyBranchComboList([...departmentList]);
        setOriMajorComboList(majorList);
        setFilterMajorComboList([...majorList]);
    }

    const loadSetting = async () => {
        let res = await getActivateSettingById("02");
        if(res.status){
            console.log("loadSetting: ", res.data);
            let resData: IActivateSetting = res.data;
            setSettingData(resData);
        }
    }
    
    const loadUserPermission = async () => {
        if(authen && authen.sessionUser){
            setUserId(authen.sessionUser.currentUser.id);
            await permissionHelper.loadUserPermission(relavantPermissionList, authen);
            setHasSearchPermission(permissionHelper.hasPermission(PermissionName.THESIS_SEARCH));
        }
    }

    const removeThesis = async (removeId: number) => {
        if(authen && authen.sessionUser){
            let resData: ApiResponse = await deleteThesis(removeId, authen.sessionUser.currentUser.id);
            if(resData.status){
                // let thesis: ThesisData = resData.data;
                notification.success({message: "ลบข้อมูลสำเร็จ", description: resData.message})
                // navigate(`/management/thesis/form/${thesis.id}`);
                onSearch();
            }
        }
    }

    // const loadUserPermission = async () => {
    //     // console.log("loadUserPermission");
    //     if(authen && authen.sessionUser){
    //         // console.log("loadUserPermission auth");
    //         let permNameList = [PermissionName.THESIS_SEARCH];
    //         let userId = authen.sessionUser.currentUser.id;
    //         let resData: ApiResponse = await getAuthPermission(userId, permNameList);
    //         if(resData.status){
    //             let data: {[key: string]: string} = resData.data;
    //             // console.log("Permission Data: ", data);
    //             setCurrentPermission(data);
    //             let hasSearchPerm = data[PermissionName.THESIS_SEARCH] != undefined && data[PermissionName.THESIS_SEARCH] != null;
    //             setHasSearchPermission(hasSearchPerm);
    //             // setCanApprove(hasApprovePerm);
    //             // setHasChangeStatusPerm(hasApprovePerm || hasRejectPerm);
    //         }
    //     }

    // }
    
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        // console.log('params', pagination, filters, sorter, extra);
    }
    const handleCancel = () => {setPreviewVisible(false)};
    const refNameChange = (e: any) => {
        setRefName(e.target.value);
    }
    const openAddModal = () => {
        setPreviewVisible(true);
    }
    const addNewThesis = async () => {
        if(authen && authen.sessionUser){
            let thesisData = {
                thesis_name: thesisName,
                thesis_abstract: '',
                user_id: authen.sessionUser.currentUser.id,
            }
            let resData: ApiResponse = await addThesis(thesisData);
            if(resData.status){
                let thesis: ThesisData = resData.data;
                notification.success({message: "บันทึกข้อมูลสำเร็จ", description: resData.message})
                navigate(`/management/thesis/form/${thesis.id}`);
            }
        }
    }
    const checkDupAndAddNewThesis = async () => {
        let dupThesisRes = await checkDuplicateTitle(thesisName);
        if(dupThesisRes.status){
            let dupThesisList: ThesisData[] = dupThesisRes.data;
            if(dupThesisList.length > 0){
                notification.warning({message: "ตรวจพบชื่อวิทยานิพนธ์ซ้ำ"})
            }else{
                addNewThesis();
            }
        }
    }
    const onSearch = async () => {
        if(authen && authen.sessionUser){
            let resData = await getThesisByParam({
                user_id: authen.sessionUser.currentUser.id,
                upload_name: uploadName.trim() == "" ? null : uploadName.trim(),
                school_year: schoolYear.trim() == "" ? null : schoolYear.trim(),
                faculty_id: selectedFaculty == "ALL" ? null : selectedFaculty,
                branch_id: selectedFacultyBranch == "ALL" ? null : selectedFacultyBranch,
                major_id: selectedMajor == "ALL" ? null : selectedMajor,
                status: selectedStatus == "ALL" ? null : selectedStatus
            });
            if(resData.status){
                let thesisList: ThesisData[] = resData.data;
                setDataList(thesisList);
            }
            setActiveKey("0");
        }
    }
    const onFacultyChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        setSelectedFaculty(value);
        setSelectedFacultyBranch("ALL");
        setSelectedMajor("ALL");
        if(value == "ALL"){
            setFilterFacultyBranchComboList([...oriFacultyBranchComboList]);
            setFilterMajorComboList([...oriMajorComboList]);
            return;
        }
        const facultyId: number = Number(value);
        let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
            return branch.faculty_id == facultyId || branch.id == -1;
        });
        setFilterFacultyBranchComboList(filterBranch);

        let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
            return branch.faculty_id == facultyId;
        });
        setFilterMajorComboList(filterMajor);
    }

    const onFacultyBranchChange = (value: string) => {
        console.log("onFacultyBranchChange: ", value);
        setSelectedFacultyBranch(value);
        setSelectedMajor("ALL");
        const facultyBranchId: number = Number(value);
        let currentFaculty = -1;
        if(selectedFaculty != "ALL"){
            currentFaculty = Number(selectedFaculty);
        }
        let facultyBranchItem: FacultyBranch | undefined = oriFacultyBranchComboList.find((value) => {return value.id == facultyBranchId});
        if(facultyBranchItem){
            const facultyBranchItemValue = facultyBranchItem;
            if(facultyBranchItemValue.faculty_id > 0){
                setSelectedFaculty("" + facultyBranchItemValue.faculty_id);
            }
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == facultyBranchItemValue.faculty_id || currentFaculty == -1|| branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == facultyBranchItemValue.id && (currentFaculty == -1 || currentFaculty == branch.faculty_id);
            });
            setFilterMajorComboList(filterMajor);
        }
        // setFilterFacultyBranchComboList(filterBranch);
    }

    const onMajorChange = (value: string) => {
        console.log("onMajorChange: ", value);
        setSelectedMajor(value);
        const majorId: number = Number(value);
        let majorItem: FacultyBranch | undefined = oriMajorComboList.find((value) => {return value.id == majorId});
        if(majorItem){
            const majorItemValue = majorItem;
            setSelectedFaculty("" + majorItemValue.faculty_id);
            setSelectedFacultyBranch("" + majorItemValue.department_id);
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == majorItemValue.faculty_id || branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == majorItemValue.department_id && branch.faculty_id == majorItemValue.faculty_id;
            });
            setFilterMajorComboList(filterMajor);
        }
    }
    
    const renderFacultyOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกคณะ</Option>);
        for(let i = 0; i < facultyComboList.length; i++){
            let fac = facultyComboList[i];
            optionList.push(<Option value={`${fac.id}`}>{fac.faculty_name}</Option>);
        }
        return optionList;
    }

    const renderFacultyBranchOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกภาควิชา</Option>);
        for(let i = 0; i < filterFacultyBranchComboList.length; i++){
            let branch = filterFacultyBranchComboList[i];
            optionList.push(<Option value={`${branch.id}`}>{branch.branch_name}</Option>);
        }
        return optionList;
    }
    const renderMajorOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกสาขา</Option>);
        for(let i = 0; i < filterMajorComboList.length; i++){
            let maj = filterMajorComboList[i];
            optionList.push(<Option value={`${maj.id}`}>{maj.branch_name}</Option>);
        }
        return optionList;
    }

    const collapseChange = (key: string | string[]) => {
        // console.log("collapseChange: ", key);
        setActiveKey(key);
    };

    const headerPanel1 = () => {
        return (
            <div style={{fontSize: "16", fontWeight: "bold"}}>
                {labelLang("ส่วนค้นหา", "Search Module", currentLang)}
            </div>
        )
    }
    
    const columns = [
        {
            title: labelLang("ชื่อห้วข้อ", "Thesis Title", currentLang),
            dataIndex: 'thesis_name',
            key: 'thesis_name',
            sorter: (a: ThesisData, b: ThesisData) => a.thesis_name.localeCompare(b.thesis_name),
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("ผู้อัปโหลด", "Sentder Name", currentLang),
            key: 'upload_user_name',
            sorter: (a: ThesisData, b: ThesisData) => `${a.upload_user_firstname} ${a.upload_user_lastname}`.localeCompare(`${b.upload_user_firstname} ${b.upload_user_lastname}`),
            render: (data: ThesisData) => {return `${data.upload_user_firstname} ${data.upload_user_lastname}`},
        },
        {
            title: labelLang("คณะ", "Faculty", currentLang),
            dataIndex: 'faculty_name',
            key: 'faculty_name',
            sorter: (a: ThesisData, b: ThesisData) => a.faculty_name != undefined && b.faculty_name != undefined ? a.faculty_name.localeCompare(b.faculty_name) : 0,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang('สาขา', "Major", currentLang),
            dataIndex: 'major_name',
            key: 'major_name',
            sorter: (a: ThesisData, b: ThesisData) => a.major_name != undefined && b.major_name != undefined ? a.major_name.localeCompare(b.major_name) : 0,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang('วันที่สร้าง', "Sent Date", currentLang),
            key: 'create_date',
            sorter: (a: ThesisData, b: ThesisData) => a.create_date.localeCompare(b.create_date),
            render: (data: ThesisData) => {return convertDBDate2Show(data.create_date)},
        },
        {
            title: labelLang('สถานะปัจจุบัน', "Status", currentLang),
            dataIndex: 'status',
            key: 'status',
            sorter: (a: ThesisData, b: ThesisData) => a.status.localeCompare(b.status),
            // render: (data: any) => {data.age},
            render: (data: string, item: ThesisData, index: number) => {return <span style={{color: getStatusTextColorCode(data)}}>{data}</span>}
        },
        // {
        //   title: 'วันที่แก้ไขล่าสุด',
        //   dataIndex: 'location_name',
        //   key: 'location_name',
        // },
        {
          title: 'Action',
          key: 'action',
          render: (text: string, record: ThesisData) => (
            <Space size="middle">
                <Link to={`/management/thesis/form/${record.id}`}><EditTwoTone /></Link>
                { record.upload_user_id === userId && record.status === 'DRAFT' ?
                <Popconfirm 
                    title={labelLang('ต้องการลบข้อมูลนี้หรือไม่?', "Do you want to delete this submission?", currentLang)}
                    onCancel={() => {}}
                    onConfirm={() => {removeThesis(record.id)}}
                    icon={<QuestionCircleFilled />}
                >
                    <DeleteTwoTone />
                </Popconfirm> : null
                }
            </Space>
          ),
        },
    ]
    const renderSearchSection = () => {
        return hasSearchPermission ? (
            
            <Collapse 
                activeKey={activeKey} 
                accordion 
                style={{marginBottom: 10, borderRadius: 5}} 
                onChange={collapseChange}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Collapse.Panel header={headerPanel1()} key={1}>
                    <div className={styles['search-container']}>
                        <div className={styles['search-component']}>
                            <div className={styles['input-line']}>
                                <Input type={"text"} placeholder={labelLang('ชื่อผู้อัปโหลด', "Sentder Name", currentLang)} value={uploadName} onChange={(e) => {setUploadName(e.target.value)}} />
                            </div>
                        </div>
                        <div className={styles['search-component']}>
                            <div className={styles['input-line']}>
                                <Input type={"text"} placeholder={labelLang('ปีการศึกษา', "Academic Year", currentLang)} value={schoolYear} onChange={(e) => {setSchoolYear(e.target.value)}} />
                            </div>
                        </div>
                        <div className={styles['search-component']}>
                            <div className={styles['input-line']}>
                                <Select
                                    showSearch
                                    placeholder={labelLang('เลือกคณะ', "Select Faculty", currentLang)}
                                    optionFilterProp="children"
                                    onChange={onFacultyChange}
                                    // onSearch={onSearch}
                                    value={selectedFaculty}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    className={styles['select-full']}
                                    style={{marginRight: 4}}
                                >
                                    {renderFacultyOption()}
                                </Select>
                            </div>
                        </div>
                        <div className={styles['search-component']}>
                            <div className={styles['input-line']}>
                                <Select
                                    showSearch
                                    placeholder={labelLang('เลือกภาควิชา', "Select Major", currentLang)}
                                    optionFilterProp="children"
                                    className={styles['select-half']}
                                    value={selectedFacultyBranch}
                                    onChange={onFacultyBranchChange}
                                    style={{marginRight: 4}}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderFacultyBranchOption()}
                                </Select>
                                <Select
                                    showSearch
                                    placeholder={labelLang("เลือกสาขาวิชา", "Select Branch", currentLang)}
                                    optionFilterProp="children"
                                    className={styles['select-half']}
                                    value={selectedMajor}
                                    onChange={onMajorChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderMajorOption()}
                                </Select>
                            </div>
                        </div>
                        <div className={styles['search-component']}>
                            <div className={styles['input-line']}>
                                <Select
                                    showSearch
                                    placeholder={labelLang("เลือกสถานะเอกสาร", "Select Status", currentLang)}
                                    optionFilterProp="children"
                                    value={selectedStatus}
                                    onChange={(value) => {setSelectedStatus(value)}}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    className={styles['select-full']}
                                >
                                    <Option value="ALL">ทุกสถานะ(All)</Option>
                                    <Option value="DRAFT">DRAFT</Option>
                                    <Option value="PENDING">PENDING</Option>
                                    <Option value="APPROVE">APPROVE</Option>
                                    <Option value="REJECT">REJECT</Option>
                                </Select>
                            </div>
                        </div>
                        {/* <div className={styles['search-component']}>
                            <span style={{marginRight: 5}}>วันที่อัปโหลด: </span>
                            <RangePicker
                                defaultValue={[moment(), moment()]}
                                format={dateFormat}
                            />
                        </div> */}
                        {/* <div className={styles['search-component']}>
                            <Input type={"text"} placeholder={"ชื่ออ้างอิง"} />
                        </div> */}
                        <div className={styles['search-component-submit']}>
                            <Button type="primary" style={{marginRight: 5}} onClick={onSearch}>{labelLang("ค้นหา", "Search", currentLang)}</Button>
                            <Button type="default">Reset</Button>
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
        ) : null;
    }
    const renderSettingText = () => {
        if(settingData){
            let resultList = [
                (<div className={styles['modal-header-text']}>{labelLang("ประจำเทอมที่", "Term", currentLang)} {settingData.semester} {labelLang("ปีการศึกษา", "Academic Year", currentLang)} {settingData.school_year + 543}</div>),
                (
                    <div className={styles['modal-sub-header-text']}>
                        {
                            `${labelLang("คำประกาศ", "Declaretion", currentLang)}: `
                            + `${labelLang(settingData.remark == null ? "" : settingData.remark, settingData.remark_en == null ? "" : settingData.remark_en, currentLang)}`
                        
                        }
                    </div>
                ),
            ]
            return resultList;
        }
        return null;
    }
    return (
        <div className={styles['main-container']}>
            {renderSearchSection()}
            <div className={styles['list-container']}>
                <div className={styles['button-container']}>
                    <Button type={'primary'} onClick={() => {openAddModal()}}>{labelLang("เพิ่มรายการใหม่", "New Submission", currentLang)}</Button>
                </div>
                <Table columns={columns} dataSource={dataList} onChange={onChange}/>
            </div>
            <Modal
                visible={previewVisible}
                title={labelLang("เพิ่มรายการส่งไฟล์ธีสิสใหม่", "New Submission", currentLang)}
                onCancel={() => {handleCancel()}}
                onOk={() => {checkDupAndAddNewThesis()}}
            >
                <div className={styles['modal-main-container']}>
                    {renderSettingText()}
                    <div className={styles['input-container']}>
                        <Input type={"text"} placeholder={labelLang("ชื่อหัวข้อ", "Thesis Title", currentLang)} value={thesisName} onChange={(e) => {setThesisName(e.target.value)}}/>
                        {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ThesisUploadList;